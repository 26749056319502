export class GeoUtil {
  static getItemDesc(item, options = {}) {
    let dsc = ''
    const frazDiDesc = options.frazDiDesc || ' frazione di '

    if (item.mypos) {
      dsc = item.label
    } else if (item.fraz && item.fraz !== '') {
      dsc = item.fraz + frazDiDesc + item.com + ' (' + item.prov + ')'
    } else if (item.com && item.com !== '') {
      dsc = item.com + ' (' + item.prov + ')'
    } else if (item.prov && item.prov !== '') {
      dsc = item.prov
    } else if (item.reg && item.reg !== '') {
      dsc = item.reg
    } else if (item.zone && item.zone !== '') {
      dsc = 'zona ' + item.zone
    }

    dsc += (item.t === 6 || item.t === 7) && item.topo && item.topo !== '' 
		  ? ' ' + item.topo + (item.t === 7 && item.nc ? ' ' + item.nc : '')
		  : '';

    return dsc
  }
}

import { Class, LatLng, latLngBounds, marker, polyline, featureGroup, circleMarker } from 'leaflet'
import { Templates } from '../template/Templates'
import { ConstantRoute, buildRouteUrl, fetchRouteData } from './routeService'

export const PGRoute = Class.extend({
  initialize: function (routeParams, callback) {
    // Calcola percorso
    // routeParams:
    // id: Unique identifier specifyed by the client																												??? a che serve? posso non passarlo?
    // lon: Lon of visible map center																																				??? posso metterlo 0 fisso
    // lat: Lat of visible map center																																				??? posso metterlo 0 fisso
    // z: Zoom																																															??? posso metterlo 0 fisso
    // PGPoints:[] array di PGPoint. Il primo è lo start, l'ultimo è l'end del percorso,
    // Devono essere almeno 2 e se il primo e l'ultimo coincidono devono essere presenti almeno 3
    // rt: Route type ('c' = Car, 'p' = Pedestrian) - default 'C'
    // xpix: Width of route area (pixels)																																		??? posso non passarlo proprio
    // ypix: Height of route area (pixels)																																	??? posso non passarlo proprio
    // alt: numero percorsi alternativi se non specificato passerà 0
    // color: colore del percorso default blu
    // lineWidth: spessore del percorso default 3
    // opacity: opacity del percorso default 1
    // textOpts: opzioni per la stampa del percorso, se textOpts non è specificato il percorso testuale
    //           non viene restituito, se template è vuoto viene usato un template di default
    //       {
    //        id: 'id del div in cui inserire il percorso',
    //        template: template handlebars, default: defaultTemplateRoute.js
    //        helpers: {} helper da usare per il template passato, default {}
    //        spinner: spinner da visualizzare in attesa del percorso, default PGRUOTE_SPINNER
    //       }
    // host: Can be used instead of the default
    // mode: If 'tc' call new json service
    // date: Date requested for the route with public transports; in format RFC3339:
    // es. 2011-01-21T12:34:55
    this.map = null
    this.routeParams = routeParams
    this.routeParams.callback = callback
    this.callback = callback
    this.route = null
    this.polylines = []
    this.selectedPolylineIndex = 0
    this.msgError = ''
    if (this.routeParams.textOpts) {
      this.textEl = document.getElementById(this.routeParams.textOpts.id)
      this.spinner = this.routeParams.textOpts.spinner ? this.routeParams.textOpts.spinner : ConstantRoute.PGRUOTE_SPINNER
    }
  },

  // Calcola percorso
  getRoute: function (map) {
    this.map = map
    this.msgError = ''

    const url = buildRouteUrl(this.routeParams, 'jsonp')

    // inserisce lo spinner
    if (this.textEl) this.textEl.innerHTML = this.spinner

    return new Promise((resolve, reject) => {
      /* jsonp */
      const script = document.createElement('script')
      script.src = url
      script.onerror = () => {
        const error = new Error('Calcolo percorso fallito')
        error.type = ConstantRoute.ROUTE_ERROR_TYPE_FAILED
        reject(error)
        if (this.textEl) this.textEl.innerHTML = '' // rimuove lo spinner
        this.msgError = error.message
        map.removeRoute()
      }
      document.head.appendChild(script)

      window.defaultCallbackRouting = (route) => {
        this.route = route
        this.drawRoute(route, map, 'jsonp')
        resolve(route)
      }
    })
  },

  addRoute(routeList, map, typeCall = 'json') {
    const newOptions = {
      color: this.routeParams.color || ConstantRoute.PGROUTE_COLOR,
      weight: this.routeParams.lineWidth || ConstantRoute.PGRUOTE_LINE_WIDTH,
      opacity: this.routeParams.opacity || ConstantRoute.PGROUTE_OPACITY_HIGH,
      smoothFactor: 1
    }

    map.routePathLayerGroup = featureGroup().addTo(map)
    map.routePointsLayerGroup = featureGroup().addTo(map)

    // Crea un array di oggetti Polyline (uno per ogni percorso)
    for (let i = 0; i < routeList.length; i++) {
      const routeCoords = routeList[i]
      const polylineObj = polyline(routeCoords, newOptions)
      polylineObj.addTo(map.routePathLayerGroup)
      this.polylines.push(polylineObj)
    }

    // mette in evidenza il percorso principale e rende grigi i percorsi alternativi
    this.polylines[0].bringToFront()
    for (let i = 1; i < this.polylines.length; i++) {
      this.polylines[i].setStyle({ color: '#A7A5A5', opacity: ConstantRoute.PGROUTE_OPACITY_MEDIUM })
    }

    this.routeParams.PGPoints.forEach((point) => {
      point.options.draggable = true
      if (!point.hasDragEndEvt) {
        point.hasDragEndEvt = true
        point.on('dragend', () => {
          map.cleanRoute()
          this.routeParams.PGPoints.forEach((p) => p.dragging.disable())

          if (typeCall === 'json') {
            fetchRouteData(this.routeParams, this)
              .then((routeData) => {
                this.drawRoute(routeData, map, 'json')
                this.routeParams.PGPoints.forEach((p) => p.dragging.enable())
                //this.addEventRoute(map)
                this.routeParams.updatePgPointCb(routeData)
              })
              .catch((error) => {
                console.error('Errore nel recupero del percorso:', error)
                map.removeRoute()
              })
          } else {
            // typecall jsonp
            map.getRoute(this.routeParams, this.callback, true).then(() => {
              this.routeParams.PGPoints.forEach((p) => p.dragging.enable())
            })
          }
        })
      }
      point.addTo(map.routePointsLayerGroup)
    })

    // Se json aggiunge eventi in automatico
    //if (typeCall === 'json') this.addEventRoute(map)
  },

  // Metodo che genera l'html con le indicazioni del percorso testuale sulla base del template Handlebars in ingresso (con eventuali helpers)
  addRouteText(map) {
    if (this.route && this.routeParams.textOpts) {
      const template = this.routeParams.textOpts.template || Templates.defaultTemplateRoute
      const helpers = this.routeParams.textOpts.helpers || {}
      const compiledTemplate = Templates.compile(template)
      let html
      html = compiledTemplate(this.route, { helpers })
      this.textEl.innerHTML = html
      this.addEventRoute(map)
    }
  },

  addEventRouteHeader(map, selHeaderRoute = '[data-alt]', selDetailRoute = '[data-lat][data-lon]', selRouteClass = 'suggested-path-selected') {
    // Aggancio eventi
    const testate = document.querySelectorAll(selHeaderRoute)
    const opacity = this.routeParams.opacity || ConstantRoute.PGROUTE_OPACITY_HIGH
    testate.forEach((elemento) => {
      elemento.addEventListener('click', () => {
        const alt = elemento.getAttribute('data-alt')
        // Sfoca tutti gli altri oggetti Polyline
        for (let i = 0; i < this.polylines.length; i++) {
          if (i != alt) {
            this.polylines[i].setStyle({ color: '#A7A5A5', opacity: ConstantRoute.PGROUTE_OPACITY_MEDIUM })
          } else {
            this.polylines[i].setStyle({ color: this.routeParams.color, opacity: ConstantRoute.PGROUTE_OPACITY_HIGH })
            this.polylines[i].bringToFront()
          }
        }
        // Rendi visibile l'oggetto Polyline selezionato
        this.polylines[alt].setStyle({ color: this.routeParams.color, opacity: ConstantRoute.PGROUTE_OPACITY_HIGH })
        this.selectedPolylineIndex = alt
      })
      elemento.addEventListener('mouseover', () => {
        const alt = elemento.getAttribute('data-alt')
        if (this.selectedPolylineIndex != alt) {
          this.polylines[alt].setStyle({ color: this.routeParams.color, opacity: ConstantRoute.PGROUTE_OPACITY_HIGH })
          for (let i = 0; i < this.polylines.length; i++) {
            if (i != alt) {
              this.polylines[i].setStyle({ color: '#A7A5A5', opacity: ConstantRoute.PGROUTE_OPACITY_MEDIUM })
            } else {
              this.polylines[i].bringToFront()
            }
          }
        }
      })
      elemento.addEventListener('mouseout', () => {
        const alt = elemento.getAttribute('data-alt')
        if (this.selectedPolylineIndex != alt) {
          this.polylines[alt].setStyle({ color: '#A7A5A5', opacity: ConstantRoute.PGROUTE_OPACITY_MEDIUM })
        }
        this.polylines[this.selectedPolylineIndex].setStyle({ color: this.routeParams.color, opacity: ConstantRoute.PGROUTE_OPACITY_HIGH })
        this.polylines[this.selectedPolylineIndex].bringToFront()
      })
    })

    this.polylines.forEach((polyline, index) => {
      polyline.on('click', () => {
        this.selectedPolylineIndex = index
        this.updatePolylinesStyle()

        testate.forEach((elemento) => {
          const alt = elemento.getAttribute('data-alt')
          if (index != alt) {
            elemento.classList.remove(selRouteClass)
          } else {
            elemento.classList.add(selRouteClass)
          }
        })
      })
      polyline.on('mouseover', () => {
        if (this.selectedPolylineIndex != index) {
          this.polylines[index].setStyle({ color: this.routeParams.color, opacity: ConstantRoute.PGROUTE_OPACITY_HIGH })
          this.polylines[index].bringToFront()
        }
      })
      polyline.on('mouseout', () => {
        if (this.selectedPolylineIndex != index) {
          this.polylines[index].setStyle({ color: '#A7A5A5', opacity: ConstantRoute.PGROUTE_OPACITY_MEDIUM })
        } else {
          this.polylines[index].bringToFront()
        }
      })
    })
  },

  addEventRouteDetails(map, selHeaderRoute = '[data-alt]', selDetailRoute = '[data-lat][data-lon]') {
    let pt
    const elementiPercorso = document.querySelectorAll(selDetailRoute)
    elementiPercorso.forEach(function (elemento) {
      elemento.addEventListener('click', function () {
        const lat = this.getAttribute('data-lat')
        const lng = this.getAttribute('data-lon')
        map.flyTo(new LatLng(lat, lng), 13, { animate: true, duration: 0.5 })
      })
      elemento.addEventListener('mouseover', function () {
        const lat = this.getAttribute('data-lat')
        const lng = this.getAttribute('data-lon')

        const outerRadiusInPixels = 6 // Dimensione esterna dell'anello in pixel
        const borderWidthInPixels = 3 // Larghezza dell'anello in pixel

        // Anello
        pt = circleMarker([lat, lng], {
          color: 'grey',
          weight: borderWidthInPixels,
          fillColor: 'transparent',
          fillOpacity: 1,
          radius: outerRadiusInPixels
        }).addTo(map)
      })
      elemento.addEventListener('mouseout', function () {
        if (pt) {
          pt.remove()
        }
      })
    })
  },

  addEventRoute(map, selHeaderRoute = '[data-alt]', selDetailRoute = '[data-lat][data-lon]', selRouteClass = 'suggested-path-selected') {
    this.addEventRouteHeader(map, selHeaderRoute, selDetailRoute, selRouteClass)
    this.addEventRouteDetails(map, selHeaderRoute, selDetailRoute)
  },

  updatePolylinesStyle() {
    const opacity = this.routeParams.opacity || ConstantRoute.PGROUTE_OPACITY_HIGH

    this.polylines.forEach((polyline, index) => {
      if (index === this.selectedPolylineIndex) {
        polyline.setStyle({ color: this.routeParams.color, opacity: opacity })
        this.polylines[index].bringToFront()
      } else {
        polyline.setStyle({ color: '#A7A5A5', opacity: ConstantRoute.PGROUTE_OPACITY_MEDIUM })
      }
    })
  },

  drawRoute(route, map, callType = 'json') {
    this.route = route

    const coords = []

    for (let i = 0; i < route.alternatives.length; i++) {
      let rplanCoords = []
      for (let j = 0; j < route.alternatives[i].rplan.length; j++) {
        rplanCoords = rplanCoords.concat(route.alternatives[i].rplan[j].coords)
      }
      coords.push(rplanCoords)
    }

    const routeList = coords.map((routeCoords) => {
      return routeCoords.map((coord) => [coord.y, coord.x])
    })

    // disegna i percorsi
    this.addRoute(routeList, map, callType)

    // centra la mappa sui punti del percorso
    let bPathLG = map.routePathLayerGroup.getBounds()
    let bPointsLG = map.routePointsLayerGroup.getBounds()
    let bounds = latLngBounds(bPathLG).extend(bPointsLG)
    let adjustedFitBounds = map.adjustedFitBounds(bounds)
    map.fitBounds(adjustedFitBounds)
  }
})

export function pgroute(routeParams) {
  return new PGRoute(routeParams)
}

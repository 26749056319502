import { Marker, Util, DivIcon, point, icon } from 'leaflet'
import { PGGeoRevCod } from '../../geocoder/PGGeoRevCod.js'
import { GeoUtil } from '../../util/GeoUtil.js'

/*
 * @class PGPoint
 * @inherits Interactive layer
 * IOLMap.PGPoint is used to display clickable/draggable icons on the map. Extends `Marker`.
 */

export const PGPoint = Marker.extend({
  options: {
    // @option id
    id: undefined,
    // @option latitude
    lat: undefined,
    // @option longitute
    lon: undefined,
    // @option autocompleteObj (Opzionale): bind con un oggetto autocomplete
    autocompleteObj: undefined,
    // @option offsetX INT (Opzionale)
    offsetX: 0,
    // @option offsetY INT (Opzionale)
    offsetY: 0,
    // @option iconPin: icona del marcatore JSON (Opzionale e alternativo ad html)
    // viene data precedenza a questo parametro rispetto ad html, se presenti entrambi
    // {
    //  url: 'url dell'icona',
    //  offset: {x:offsetX in px, y:offsetY in px}  opzionale, default 12.5 e 41
    // }
    iconPin: undefined,
    // @option html: html del marcatore JSON (Opzionale e alternativo a icon)
    // viene data prcedenza ad iconUrl, se presenti entrambi
    // {
    //  html: 'url dell'icona',
    //  offset: {x:offsetX in px, y:offsetY in px}  opzionale, default 12.5 e 41
    // }
    html: undefined,
    // @option overClass: classe da aggiungere al PGPoints sul mouseover String (Opzionale)
    overClass: undefined,
    // @option tooltip visualizzato al mouseover JSON (Opzionale)
    // {
    //  html: 'html del tooltip',
    //  className: 'className del tooltip',
    //  direction: 'right|left|top|bottom|center', -> default top
    //  offset: {x:offsetX in px, y:offsetY in px}  opzionale, default offsetX e offsetY se passati
    // }
    tooltip: undefined,
    // @option popup visualizzato al mouseover JSON (Opzionale)
    // {
    //  html: 'html del popup',
    //  className: 'className del popup',
    //  direction: 'right|left|top|bottom|center'   -> default top
    //  offset: {x:offsetX in px, y:offsetY in px}  opzionale, default offsetX e offsetY se passati
    // }
    popup: undefined
  },

  /* @initialize
   */

  initialize: function (options) {
    // set current options
    Util.setOptions(this, options)

    // call parent constructor
    Marker.prototype.initialize.call(this, options)

    // gestione coordinate e spostamento
    this.lat = this.options.lat
    this.lon = this.options.lon
    this.setLatLng([this.lat, this.lon])

    // eventuale aggancio con oggetto autocomplete
    if (this.options.autocompleteObj) this.autocompleteObj = this.options.autocompleteObj

    this.on('dragend', (e) => {
      const latLng = this.getLatLng()
      this.lat = latLng.lat
      this.lon = latLng.lng

      if (this.autocompleteObj) {
        const parsedUrl = new URL(this.autocompleteObj.url)

        PGGeoRevCod.execute(this.lon, this.lat, `${parsedUrl.protocol}//${parsedUrl.hostname}`)
          .then((result) => {
            this.autocompleteObj.lat = this.lat
            this.autocompleteObj.lon = this.lon
            this.autocompleteObj.element = result
            this.autocompleteObj.desc = GeoUtil.getItemDesc(result)
            document.getElementById(this.autocompleteObj.id).value = this.autocompleteObj.desc
          })
          .catch((error) => {
            // gestisci eventuali errori
            console.log(`Errore durante la geocodifica inversa: ${error.message}`)
          })
      }
    })
    this.hasDragEndEvt = false

    // impedisce che si possa vedere il tooltip se è aperto il fumetto (popup)
    this.on('mouseover', this._customTip)
    this.on('click', this._customPop)

    // Passo al marker un icona specifica
    if (this.options.iconPin) {
      this.options.icon = icon({
        iconUrl: this.options.iconPin.url,
        iconAnchor: [this.options.iconPin?.offset?.x || 12.5, this.options.iconPin?.offset?.y || 41]
      })
    }

    // Passo al marker l'html del pin che dovrà essere visualizzato solo se non ho passato iconPin
    if (this.options.html && !this.options.iconPin) {
      const divIcon = new DivIcon({
        className: 'pgpoint-icon',
        html: this.options.html.html,
        iconAnchor: point(this.options.html?.offset?.x || 12.5, this.options.html?.offset?.y || 41)
      })
      const style = document.createElement('style')
      style.innerHTML = `
      .pgpoint-icon {
        background: none;
      }
      `
      document.head.appendChild(style)

      this.options.icon = divIcon
    }

    // Se presente, aggiungo una classe al marker sul mouseover, e la rimuovo sul mouseout
    if (this.options.overClass) {
      this.on('mouseover', function (e) {
        this._icon.classList.add(this.options.overClass)
      })

      this.on('mouseout', function (e) {
        this._icon.classList.remove(this.options.overClass)
      })
    }

    // Tooltip Add
    if (this.options.tooltip) {
      this.bindTooltip(this.options.tooltip.html, {
        className: this.options.tooltip.className,
        direction: this.options.tooltip?.direction ?? 'top',
        offset: point(this.options.tooltip?.offset?.x ?? this.options.offsetX, this.options.tooltip?.offset?.y ?? this.options.offsetY)
      }).openTooltip()
    }

    // Add fumetto
    if (this.options.popup) {
      this.bindPopup(this.options.popup.html, {
        className: this.options.popup.className,
        direction: this.options.popup?.direction ?? 'top',
        offset: [this.options.popup?.offset?.x ?? this.options.offsetX, this.options.popup?.offset?.y ?? this.options.offsetY]
      })
    }
  },

  // Tooltip
  _customTip: function () {
    if (!this.isPopupOpen()) {
      if (this.getTooltip()) this.getTooltip().setOpacity(0.9)
      else if (this.getTooltip()) this.getTooltip().setOpacity(0)
    }
  },

  // Fumetto
  _customPop: function () {
    if (!this.isPopupOpen()) {
      if (this.getTooltip()) this.getTooltip().setOpacity(0)
    }
  }
})

// @factory IOLMap.PGPoint(options : PGPoint options)
// Instantiates a PGPoint object given a geographical point and optionally an options object.
export function pgpoint(options) {
  return new PGPoint(options)
}

// PGPoint disponibile a livello globale per compatibilita vecchie API
window.PGPoint = PGPoint
